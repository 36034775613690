import { Component, Renderer2 } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading = true
  constructor(private authService: AuthenticationService, private router: Router, private renderer: Renderer2) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    })
  }

  ngOnInit(): void {
    if (window.addEventListener) {
      window.addEventListener("storage", this.sessionStorage_transfer, false);
    }
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', 'foobar');
      localStorage.removeItem('getSessionStorage');
    };
    let domainUrl = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let skc = domainUrl == 'app.myisaac.com' ? '6Lc1xFMqAAAAAF8OQsnWmnoOR77N7ot8n4gAwuFQ' : '6LdBl0sqAAAAAHk347EFMFzvTF7NKYsL8jKO3JcA';
    this.loadScript(`https://www.google.com/recaptcha/api.js?render=${skc}`);
    sessionStorage.setItem('skc', skc);
  }

  loadScript(src: string) {
    const script = this.renderer.createElement('script');
    script.src = src;
    script.async = true; // You can set async or defer attributes if needed
    this.renderer.appendChild(document.body, script);
  }

  sessionStorage_transfer(event) {
    if (!event) { event = window.event; }
    if (!event.newValue) return;
    if (event.key == 'getSessionStorage') {
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
      var data = JSON.parse(event.newValue);
      for (var key in data) {
        sessionStorage.setItem(key, data[key]);
      }
    }
  };

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }



}
