import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDeleteDialogComponent } from 'src/app/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationService } from 'src/app/services/location.service';
import { MemberService } from 'src/app/services/member.service';
import { MembersData } from 'src/app/shared/constants/membersData';
import { UserLevelData } from 'src/app/shared/constants/userRoles';
import { States } from 'src/app/shared/states';
import { MemberSignupPaymentComponent } from '../member-signup-payment/member-signup-payment.component';
import { OrganizationService } from 'src/app/services/organization.service';

class formdataU {
  dateExpires: string
  memberNotes: string
  user_visibility: string
  status: string
}

class formdataO {
  org_expire: string
  org_notes: string
  user_visibility: string
  org_status: string
}

class formdatamEmil {
  user_name: string
  user_email: string
  admin_email: string
  location_name: string
  loc_id: number
  membership_type: string
  payment_account: number
  member_id: string
  orgID: string
  orgname: string
  user_id: Number
}

@Component({
  selector: 'app-group-members-signup',
  templateUrl: './group-members-signup.component.html',
  styleUrls: ['./group-members-signup.component.scss']
})
export class GroupMembersSignupComponent implements OnInit {

  userLevel = UserLevelData.userLevels
  membersForm: FormGroup
  membershipList = []
  membershipFilteredList
  membershipListT
  volunteerList = MembersData.volunteerList
  id: string;
  maxDate
  access;
  Classification: string;
  name;
  fee;
  mailingList;
  stateList;
  member_type;
  locationsList
  organisationList = []
  organisationListType = []
  uploadedFile;
  fieldTextType: boolean;
  checkMember;
  isEmailValid;
  checkMail
  loading;
  isMemberIdValid = false;
  filterValue = null
  filterorga;
  pattern = /^\d+$/
  msg;
  selected;
  group;
  personId;
  organisationfiltered;
  checking = null;
  stateListfiltered;
  maxmember = 0;
  loadingf = false
  maxMemberMsg;
  userData;
  orgData;
  show_button: Boolean = false;
  organizationForm: FormGroup
  show_eye: Boolean = false;
  themeColor
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  currentDate = moment(new Date()).format('YYYY-MM-DD')
  yearDate = moment().add(1, 'years').format('YYYY-MM-DD')
  constructor(
    private memberService: MemberService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private datePipe: DatePipe,
    private orgService: OrganizationService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) { }



  ngOnInit(): void {
    this.stateList = States.statesList;
    this.loadingf = true;
    this.route
      .queryParams
      .subscribe(params => {
        this.id = params.loc_id
        this.name = params.name
        if (params.tc) {
          this.themeColor = `#${params.tc}`
        } else if (params.c) {
          this.themeColor = params.c
        } else {
          this.themeColor = '#ff0000'
        }
      })
    this.getLocationInfo()
    // this.getOrganisations()
    this.organizationForm = this.formBuilder.group({
      member_type: ['', Validators.required],
      org_start: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
      org_expire: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
      number_of_members: [''],
      org_name: ['', Validators.required],
      org_address: ['', Validators.required],
      org_city: ['', Validators.required],
      org_state: ['', Validators.required],
      org_zip: ['', [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]],
      org_primary_phone: ['', [Validators.required, Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)]],
      org_phone2: '',
      org_email: ['', [Validators.required, Validators.email]],
      membership_fee: [''],
      group_photo: '',
      org_notes: '',
      tc: [true, Validators.required],
      membership_type: '',
      member: this.formBuilder.array([this.initMembers()]),
    });
    this.membersForm = this.formBuilder.group({
      // user_name: ['', [Validators.required, Validators.minLength(4)]],
      user_level: [{ value: '1', disabled: true }],
      loc_id: [{ value: this.id, disabled: true }],
      // pwd: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/)]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      organizations: '',
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipcode: ['', [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]],
      tel: ['', [Validators.required, Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)]],
      work_phone: ['', Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)],
      home_phone: ['', Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)],
      user_email: ['', [Validators.required, Validators.email]],
      user_photo: '',
      dateJoined: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      dateExpires: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      personID: '',
      user_age_under_18: '',
      user_age_under_13: '',
      parent_Name: '',
      parent_Phone: ['', [Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)]],
      parent_Email: ['', [Validators.email]],
      member_type: ['', Validators.required],
      membership_fee: [''],
      volunteerAreas: [],
      mailingList: [],
      paperwork: '',
      memberNotes: '',
      ageGroup: '',
      loc_name: ['']
    })
    // this.membersForm.get('loc_id').patchValue(this.name)
    // this.membersForm.get('user_level').patchValue('User')
    // this.checkMemberId()

  }
  initMembers() {

    let eighteenYearsAgo = new Date();
    let eighteeYearsAgo = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    this.maxDate = new Date(eighteeYearsAgo)
    return this.formBuilder.group({
      user_level: [{ value: '1', disabled: true }],
      loc_id: [{ value: this.id, disabled: true }],
      // pwd: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/)]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      organizations: '',
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipcode: ['', [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]],
      tel: ['', [Validators.required, Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)]],
      work_phone: ['', Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)],
      home_phone: ['', Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)],
      user_email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      user_photo: '',
      dateJoined: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      dateExpires: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      personID: '',
      user_age_under_18: '',
      user_age_under_13: '',
      parent_Name: '',
      parent_Phone: ['', [Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)]],
      parent_Email: ['', [Validators.email]],
      member_type: [''],
      membership_fee: [''],
      volunteerAreas: [],
      mailingList: [],
      paperwork: '',
      memberNotes: '',
      ageGroup: '',
      loc_name: ['']
    })
  }
  getCardTitle(index) {
    return index == 0 ? 'Primary' : 'Secondary'
  }
  // radioChangeType(event: MatRadioChange) {
  //   const ev = event.value
  //   if( ev == "Individual" ){
  //     this.group = false
  //     this.individual = true
  //     this.membershipListT = this.membershipList.filter(e =>e.member_classification == ev)
  //   } 
  //   else if(ev == "Group"){
  //     this.group = true
  //     this.individual = false
  //     // this.membershipListT = this.organisationList
  //     console.log(this.organisationList)
  //   }

  // }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  confirmDialog() {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: "500px",
      disableClose: true,
      // panelClass: 'tool-bar',
      data: {
        message: 'Do you have an existing account?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        },
        themeColor: this.themeColor
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.router.navigate(['/login'])
      }
    });
  }

  updateOnclickGen(ev) {
    console.log(ev);
    this.membershipListT = this.membershipList.filter(e => e.membership_type == ev)[0]
    this.organizationForm.get('membership_type').patchValue(this.membershipListT.membership_type)
    this.organizationForm.get('member_type').patchValue(this.membershipListT.membership_type)
    this.organizationForm.get('number_of_members').patchValue(this.membershipListT.max_member_in_grp)
    this.organizationForm.get('membership_fee').patchValue(this.membershipListT.member_fee)
    this.selected = true
    if (this.membershipListT.doc.length >= 1) {
      console.log(this.organizationForm.get('tc').value)
      if (this.organizationForm.get('tc').value != true) {
        this.organizationForm.controls.tc.setValidators(Validators.required);
        this.organizationForm.controls.tc.updateValueAndValidity()
      }
    } else {
      this.organizationForm.controls.tc.setValidators(null)
      this.organizationForm.controls.tc.updateValueAndValidity()
    }
  }

  reLoad() {
    this.stateListfiltered = this.stateList
    this.membershipFilteredList = this.membershipList
    this.organisationfiltered = this.organisationListType
  }

  applyFilterMembership(str) {
    this.membershipFilteredList = this.membershipList
    this.membershipFilteredList = this.membershipList.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(str.toLowerCase());
      });
    });
  }
  applyStateFilter(str) {
    this.stateListfiltered = this.stateList
    this.stateListfiltered = this.stateList.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(str.toLowerCase());
      });
    });
  }
  checkEmail(event: Event, val) {
    const email = (event.target as HTMLInputElement).value;
    this.checkMail = 'Checking ...'
    if (email != '' && val == null) {
      this.memberService.checkEmail(this.id, email).subscribe(res => {
        if (res.status === 'success')
          this.checkMail = `Email-ID is already Taken`
        this.isEmailValid = false;
      }, err => {
        if (err.error.status === 'failure')
          this.checkMail = `Email-ID is Available`
        this.isEmailValid = true;
      })
    }
  }
  applyFilterOrg(str) {
    this.organisationfiltered = this.organisationListType
    this.organisationfiltered = this.organisationListType.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(str.toLowerCase());
      });
    });
  }

  selectMT(ev: MatSelectChange) {
    // console.log(ev.value) 
    let member_type = ''
    const organisationdata = this.organisationfiltered.filter(s => s.org_id == ev.value)
    member_type = organisationdata[0].member_type
    const memtype = member_type ? true : false
    const nom = organisationdata[0].number_of_members ? true : false
    // console.log(organisationdata)
    if (nom && organisationdata[0].number_of_members >= 0) {
      // console.log('member_type yes')
      if (organisationdata[0].users < organisationdata[0].number_of_members) {
        this.maxMemberMsg = '';
      } else {
        this.maxMemberMsg = 'Sorry \"' + organisationdata[0].org_name + '\" Group is Already Full'
      }
    } else if (memtype) {
      // console.log(JSON.stringify(this.membershipList))
      const membershipD = this.membershipList.filter(s => s.membership_type == member_type)
      // this.membersForm.get('member_type').patchValue(member_type)
      // console.log(JSON.stringify(membershipD[0].max_member_in_grp) , organisationdata[0].users)
      if (organisationdata[0].users < membershipD[0].max_member_in_grp) {
        this.maxMemberMsg = '';
      } else {
        this.maxMemberMsg = 'Sorry \"' + organisationdata[0].org_name + '\" Group is Already Full'
      }
    } else {
      // console.log('else')
      this.maxMemberMsg = '';
    }

  }

  radioChange(event: MatRadioChange) {
    if (event.value == 'Yes') {
      this.membersForm.get('parent_Email').setValidators([Validators.email, Validators.required]);
      this.membersForm.controls.parent_Email.updateValueAndValidity()
      this.membersForm.controls.parent_Name.setValidators(Validators.required);
      this.membersForm.controls.parent_Name.updateValueAndValidity()
      this.membersForm.controls.ageGroup.setValue('18 and under')
    } else {
      this.membersForm.controls.parent_Email.setValidators(null)
      this.membersForm.controls.parent_Email.setValue('')
      this.membersForm.controls.parent_Email.updateValueAndValidity()
      this.membersForm.controls.parent_Name.setValidators(null)
      this.membersForm.controls.parent_Name.setValue('')
      this.membersForm.controls.parent_Name.updateValueAndValidity()
      this.membersForm.controls.ageGroup.setValue('')
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  getLocationInfo() {
    this.locationService.getLocationDetails(this.name, this.id).subscribe(res => {
      this.locationsList = res.data[0];
      if (this.locationsList.ui_theme == 'Custom') {
        this.themeColor = this.locationsList.ui_primary_clor
      } else if (this.locationsList.ui_theme != 'Custom') {
        this.themeColor = this.locationsList.ui_theme
      } else {
        this.themeColor = '#ff0000'
      }
      if (this.locationsList.can_member_login != 'False') {
        this.membershipList = res.data[0].fee.filter(e => e.membership_status == 'Active' && e.can_signup == 'Yes' && e.member_classification == "Group").map(e => {
          return {
            ...e,
            memberdesc: e.doc.length >= 1 ? e.member_desc + `<br><a target="_blank" href="${e.doc[0].file_name}">Rules & Procedures Document</a>` : e.member_desc
          }
        });
        this.membershipFilteredList = this.membershipList
        if (res.data[0].status === "Active") {
          this.access = true;
          this.membershipList.length >= 1 ? this.confirmDialog() : ''

        } else {
          this.access = false;
        }
        this.loading = false;
        this.loadingf = false;
      } else {
        this.router.navigate(['inactive-account']);
      }
    }, err => {
      console.log(err)
      this.loading = false;
      this.loadingf = false;
    })
  }

  compareOrg(o1: any, o2: any) {
    return o1.label === o2;
  }

  // getOrganisations() {
  //   this.memberService.getOrganisations(this.id).subscribe(res => {
  //     this.filterorga = res.data.map(e => ({ label: e.org_name, value: e.org_id }))
  //   }, err => {
  //     console.log(err)
  //   })
  // }
  // checkMemberId() {
  //     this.memberService.getMemberFormId(this.id).subscribe(res => {
  //       if (res && res.statusCode == 200) {
  //         this.personId = res.data.newMemberId
  //         this.membersForm.get('personID').patchValue(this.personId )
  //       }
  //     }, err => {
  //       console.log(err)
  //     })
  // }

  applyFilter(str) {
    this.stateListfiltered = this.stateList
    this.stateListfiltered = this.stateList.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(str.toLowerCase());
      });
    });
  }


  uploadImage(event) {
    this.uploadedFile = event.target.files[0];
    this.membersForm.controls.user_photo.setValue(this.uploadedFile.name)
  }

  onKey(value) {
    this.filterorga = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.filterorga.filter(option => option.toLowerCase().startsWith(filter));
  }
  submit() {
    let orgPayload = { ...this.organizationForm.value }
    let memberPayload = [...this.organizationForm.value.member]
    delete orgPayload.member
    delete orgPayload.tc
    this.fee = orgPayload.membership_fee
    delete orgPayload.membership_fee
    if (orgPayload.org_start !== '')
      orgPayload.org_start = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    orgPayload.org_status = 'Inactive'
    orgPayload.member_type = orgPayload.member_type
    delete orgPayload.membership_type
    if (this.fee > 0) {
      orgPayload.user_visibility = "False"
    } else {
      orgPayload.org_expire = this.yearDate
    }
    this.orgService.addOrg(this.id, orgPayload).subscribe(orgRes => {
      if (orgRes && orgRes.statusCode == 200) {

        this.orgData = orgRes.data
        memberPayload.forEach((e, i) => {
          let memData = { ...e }
          // let memFormData = new FormData();
          memData.full_name = `${memData.first_name.replace(/ /g, "")} ${memData.last_name.replace(/ /g, "")}`
          delete memData.user_name
          delete memData.existingUser
          delete memData.membership_fee
          delete memData.loc_name
          memData.org_mem_type = 'Primary'
          memData.organizations = orgRes.data.org_id.toString();
          memData.member_type = orgPayload.member_type
          memData.loc_id = this.id
          memData.dateExpires = this.datePipe.transform(orgPayload.org_expire, 'yyyy-MM-dd')
          memData.user_level = 1
          memData.mailingGroup = ''
          memData.volunteerAreas = ''
          memData.mailingList = ''
          memData.user_level = JSON.parse(memData.user_level)
          console.log(memData)
          if (this.fee > 0) {
            this.toastr.success('Organization is Pending')
            memData.user_visibility = "False"
            this.memberService.createMember(this.id, memData).subscribe(res => {
              if (res && res.statusCode == 200) {
                memData.orgname = this.orgData.org_name
                memData.membership_amount = this.fee
                this.memberService.memberData = memData
                this.loadingf = true
                this.userData = res.data
                this.makePayment(this.userData, this.userData.token)
                // this.makePayment(payload)

              }
            }, err => {
              this.toastr.error('Something Went Wrong!');
              console.log(err)
              this.loadingf = false
            })
          } else {
            this.toastr.success(orgRes.message)
            memData.dateExpires = this.yearDate
            this.memberService.createMember(this.id, memData).subscribe(res => {
              if (res && res.statusCode == 200) {
                let memberval = res.data
                let emailpayload: formdatamEmil = new formdatamEmil
                emailpayload.user_email = memberval.user_email
                emailpayload.user_id = memberval.id
                emailpayload.user_name = memberval.full_name
                // emailpayload.admin_email = this.locationsList.admin_email
                emailpayload.admin_email = this.locationsList.email
                emailpayload.loc_id = memberval.loc_id
                emailpayload.location_name = this.locationsList.name
                emailpayload.member_id = memberval._id
                emailpayload.membership_type = this.member_type
                emailpayload.payment_account = this.fee
                emailpayload.orgname = this.orgData.org_name
                emailpayload.orgID = this.orgData._id

                // console.log(emailpayload)
                this.memberService.newMemberNotifMail(memberval.loc_id, emailpayload).subscribe(res => {
                  this.loadingf = false
                }, err => {
                  console.log(err)
                })
                this.toastr.success('Group & Member Created');
                this.router.navigate(['/member-sucess'],
                  { queryParams: { type: 'org' } })
                this.toastr.success(res.message);
              }
            }, err => {
              console.log(err)
              this.loadingf = false
            })
          }
        })
      }
    }, err => {
      this.toastr.error(err.error.message.replace(/[\"]/g, ''))
      console.log(err)
    })
  }
  submitold() {

    let orgPayload = { ...this.organizationForm.value }
    let memberPayload = [...this.organizationForm.value.member]
    // console.log(orgPayload)
    // console.log(memberPayload)
    if (this.isEmailValid) {
      this.loadingf = true
      // console.log(this.membersForm.valid, this.membersForm.value)
      // if (this.membersForm.valid) {
      let payloadD = { ...this.organizationForm.value.member }
      this.membersForm.removeControl('membership_fee')
      this.membersForm.removeControl('URL')
      this.membersForm.removeControl('loc_name')
      let payload = { ...this.organizationForm.value.member }
      this.fee = orgPayload.member_fee
      payload.user_level = '1';
      payload.full_name = `${payload.first_name.replace(/ /g, "")} ${payload.last_name}`
      // payload.URL = this.router.url
      payload.memberNotes = 'Payment Not Done while Resisting through Member Sign up Link'
      payload.loc_id = this.id
      payload.volunteerAreas = ''
      // payload.personID = this.personId
      payload.mailingList = ''
      payload.member_type = this.organizationForm.value.membership_type
      this.member_type = this.organizationForm.value.membership_type
      payload.mailingGroup = ''
      // console.log(JSON.stringify(payload))
      payloadD.loc_id = this.id
      payloadD.membership_amount = this.organizationForm.value.member_fee
      payloadD.member_type = this.organizationForm.value.membership_type
      payloadD.user_level = '1'
      // if(fee>0){
      // payload.status = "Pending"
      // }
      payloadD.URL = this.router.url
      payload.user_level = JSON.parse(payload.user_level)
      payloadD.full_name = `${payloadD.first_name.replace(/ /g, "")} ${payloadD.last_name.replace(/ /g, "")}`
      if (payload.organizations !== '')
        payload.organizations = typeof payload.organizations === 'object' ? payload.organizations : this.organisationList.find(f => f.label === payload.organizations)
      if (payload.CORI_exp_date === null)
        payload.CORI_exp_date = ''
      this.memberService.memberData = payload
      if (this.fee > 0) {
        payload.user_visibility = "False"
        this.memberService.createMember(this.id, payload).subscribe(res => {
          if (res && res.statusCode == 200) {
            this.loadingf = true
            this.userData = res.data
            this.makePayment(this.userData, this.userData.token)
            // this.makePayment(payload)

          }
        }, err => {
          this.toastr.error('Something Went Wrong!');
          console.log(err)
          this.loadingf = false
        })
      } else {
        const today = new Date(payload.dateJoined);
        const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate() - 1);
        payload.dateExpires = this.datePipe.transform(new Date(nextYear), 'yyyy-MM-dd')
        payload.org_status = 'Active'
        this.memberService.createMember(this.id, payload).subscribe(res => {
          if (res && res.statusCode == 200) {
            let memberval = res.data
            let emailpayload: formdatamEmil = new formdatamEmil
            emailpayload.user_email = memberval.user_email
            emailpayload.user_id = memberval.id
            emailpayload.user_name = memberval.full_name
            // emailpayload.admin_email = this.locationsList.admin_email
            emailpayload.admin_email = this.locationsList.email
            emailpayload.loc_id = memberval.loc_id
            emailpayload.location_name = this.locationsList.name
            emailpayload.member_id = memberval._id
            emailpayload.membership_type = this.member_type
            emailpayload.payment_account = this.fee
            // console.log(emailpayload)
            this.memberService.newMemberNotifMail(memberval.loc_id, emailpayload).subscribe(res => {
              this.loadingf = false
            }, err => {
              console.log(err)
            })
            this.router.navigate(['/member-sucess'],
              { queryParams: { type: 'org' } })
            this.toastr.success(res.message);
          }
        }, err => {
          console.log(err)
          this.loadingf = false
        })
      }
      // }
    }
  }


  makePayment(res, token) {
    this.loadingf = false

    const dialogRef = this.dialog.open(MemberSignupPaymentComponent, {
      width: "100vw",
      maxWidth: "1450px",
      minWidth: "450px",
      height: "750px",
      data: {
        data: this.locationsList,
        userData: res,
        themeColor: this.themeColor
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      this.loadingf = true

      if (res == 'payment') {
        // this.paymentDone()
        let payload: formdataU = new formdataU
        payload.user_visibility = ""
        payload.dateExpires = this.yearDate
        payload.status = 'Active'
        payload.memberNotes = 'Registered through Group Sign up Link'
        let orgpayload: formdataO = new formdataO
        orgpayload.user_visibility = ""
        orgpayload.org_expire = this.yearDate
        orgpayload.org_status = 'Active'
        orgpayload.org_notes = 'Registered through Group Sign up Link'
        this.orgService.updateOrg(this.id, this.orgData._id, orgpayload).subscribe(res => {
          this.loadingf = false
          this.memberService.updatesignupMember(this.id, payload, this.userData._id, token, this.member_type, this.fee).subscribe(res => {
            let memberval = res.data
            // let emailpayload: formdatamEmil = new formdatamEmil
            // emailpayload.user_email = memberval.user_email
            // emailpayload.user_id = memberval.id
            // emailpayload.user_name = memberval.full_name
            // emailpayload.admin_email = this.locationsList.admin_email
            // emailpayload.loc_id = memberval.loc_id
            // emailpayload.location_name = this.locationsList.name
            // emailpayload.member_id = memberval._id
            // emailpayload.membership_type = this.member_type
            // emailpayload.payment_account = this.fee
            // console.log(emailpayload)
            this.loadingf = false

            this.toastr.success('Group & Member Created');
            this.router.navigate(['/member-sucess'],
              { queryParams: { type: 'org' } })
          }, err => {
            console.log(err)
            this.loadingf = false
            this.toastr.error(err);

          })
        })

      } else if (res != 'paymentError') {
        this.loadingf = false
        // this.memberService.deleteMemberfromDB(this.id, this.userData._id).subscribe(res => {
        // }, err => {
        //   console.log(err)
        // })
        this.orgService.deleteOrg(this.id, this.orgData._id).subscribe(res => {
          this.memberService.deleteMemberfromDB(this.id, this.userData._id).subscribe(res => {
          }, err => {
            console.log(err)
          })
        }, err => {
          console.log(err)
        })
      }
    })
  }


  // paymentDone() {
  //   this.loadingf = false
  //   let payloadD = { ...this.membersForm.value }
  //   this.membersForm.removeControl('membership_fee')
  //   this.membersForm.removeControl('URL')
  //   this.membersForm.removeControl('loc_name')
  //   let payload = { ...this.membersForm.value }
  //   const fee = this.membersForm.value.member_type.member_fee
  //     payload.user_level = '1';
  //     payload.full_name =  payload.first_name + ' ' + payload.last_name
  //     // payload.URL = this.router.url
  //     payload.loc_id = this.id
  //     payload.volunteerAreas = ''
  //     payload.personID = this.personId
  //     payload.mailingList = ''
  //     payload.member_type = this.membersForm.value.member_type.membership_type
  //     payload.mailingGroup = ''
  //     // console.log(JSON.stringify(payload))
  //     payloadD.loc_id = this.id
  //     payloadD.membership_amount = this.membersForm.value.member_type.member_fee
  //     payloadD.member_type = this.membersForm.value.member_type.membership_type
  //     payloadD.user_level = '1'
  //     payloadD.URL = this.router.url
  //     payloadD.full_name = `${payloadD.first_name} ${payloadD.last_name}`
  //     // console.log(payload.organizations)
  //     if (payload.organizations !== '')
  //         payload.organizations = typeof payload.organizations === 'object' ? payload.organizations : this.organisationList.find(f => f.label === payload.organizations)
  //       if (payload.CORI_exp_date === null)
  //         payload.CORI_exp_date = ''
  //       let formData = new FormData();
  //       if (this.uploadedFile) {
  //         delete payload.user_photo
  //         formData.append('user_photo', this.uploadedFile);
  //       }
  //       for (var key in payload) {
  //         formData.append(key, payload[key]);
  //       }
  //     this.memberService.memberData = payloadD
  //     this.memberService.createMember(formData).subscribe(res => {
  //         if (res && res.statusCode == 200) {
  //           // this.router.navigateByUrl('/login')
  //           // this.toastr.success(res.message);
  //           this.loadingf = true
  //         }
  //       }, err => {
  //         console.log(err)
  //         this.loadingf = true
  //       })
  //   }
}

